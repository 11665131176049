<script>
import answerMapper from "@/data/answerMapper";

export default {
  name: "SkincareMessage",
  props: {
    notYetConsult: {
      type: Boolean,
    },
    itemLength: {
      type: Number,
    },
  },
  data() {
    return {
      noItemMessage: {
        ja: "申し訳ございません。あなたが選択した内容でご提案できる商品はございません。",
        en: "We are sorry. We do not have any products to offer you based on your selection.",
        zh: "对不起。没有可以根据你选择的内容提出建议的商品。",
      },
      notYetConsultMessage:{
        ja: "このカテゴリーの商品はまだご相談いただいておりません。",
        en: "No products in this category have been consulted yet.",
        zh: "这个类别的商品还没有商量。",
      },
      answerMapper,
    };
  },
};
</script>

<template>
  <div class="messageWrapper">
    <div class="noItemDescription">
      <div v-if="notYetConsult" class="notYetConsult">
        <!-- use v-if to show the profile by language -->
        <p>{{ notYetConsultMessage[$i18n.locale] }}</p>
        <router-link to="/flow/item-suggestions">
          {{ $t("mykarteSkinCareToItemSuggestions") }}
        </router-link>
      </div>
      <div v-else-if="itemLength == 0" class="noItem">
        <!-- use v-if to show the profile by language -->
        <p>{{ noItemMessage[$i18n.locale] }}</p>
        <router-link to="/flow/item-suggestions">
          {{ $t("mykarteSkinCareToItemSuggestions") }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<style scoped>
.messageWrapper {
  background-color: #fff;
  margin-top: 5px;
  padding-top: 10px;
}
.mappedAnswers_container {
  padding: 0 40px;
}
.mappedAnswers {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}
.mappedAnswers p {
  padding-right: 10px;
  padding-bottom: 3px;
  font-size: 12px;
}
.noItemDescription {
  padding: 0 40px;
  text-align: center;
}
.notYetConsult {
  padding: 0 40px;
  text-align: center;
}
.notYetConsult p {
  padding: 12px 0;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}
.noItem p {
  padding: 14px 0;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}
a {
  padding: 11px 14px;
  background-color: #fadad2;
  display: inline-block;
  color: #444;
  margin-bottom: 16px;
}
</style>
