<template>
  <main>
    <section class="mykarte_title">
      <div class="mykarte_title_container">
        <h1>{{ $t('mykarte') }}</h1>
        <p>{{ $t('mykarteMainDesc') }}</p>
      </div>
    </section>
    <div
      v-if="hasPreviousResult"
      class="genre_container"
    >
      <div
        class="genre"
        :class="[selectedGenre === 'SkinCheck' ? 'genre_selected' : '']"
        @click="changeTab('SkinCheck')"
      >
        {{ $t('categorySkinCheck') }}
      </div>
      <div
        class="genre"
        :class="[selectedGenre === 'SkinCare' ? 'genre_selected' : '']"
        @click="changeTab('SkinCare')"
      >
        {{ $t('categorySkincare') }}
      </div>
      <div
        class="genre"
        :class="[selectedGenre === 'Makeup' ? 'genre_selected' : '']"
        @click="changeTab('Makeup')"
      >
        {{ $t('categoryMakeup') }}
      </div>
    </div>
    <KeepAlive>
    <component
      :is="viewComponent"
      @setSituation="setSituation"
    ></component>
    </KeepAlive>
  </main>
</template>
<script>
import SkinCheck from "@/views/MyKarteAreas/SkinCheck.vue"
import SkinCare from "@/views/MyKarteAreas/SkinCare.vue"
import Makeup from "@/views/MyKarteAreas/Makeup.vue"

export default {
  name: 'MyKarte',
  components: {
    SkinCheck,
    SkinCare,
    Makeup
  },
  props: {
    genre: {
      type: String,
      default: "skincheck"
    }
  },
  data() {
    return {
      // 初期設定
      count: 0,
      hasPreviousResult: false,
      selectedGenre: "SkinCheck"
    }
  },
  watch: {
    $route(to){
      if(to.fullPath.startsWith("/mykarte/skincheck")){
        this.selectedGenre = "SkinCheck"
      }else if(to.fullPath.startsWith("/mykarte/skincare")){
        this.selectedGenre = "SkinCare"
      }else if(to.fullPath.startsWith("/mykarte/makeup")){
        this.selectedGenre = "Makeup"
      }
    }
  },
  computed:{
    viewComponent(){
      if(this.$route.path.endsWith("/mykarte") || this.$route.path == "/mykarte/skincheck"){
        return SkinCheck
      }else if(this.$route.path.indexOf("skincare")!=-1){
        return SkinCare
      }else if(this.$route.path.indexOf("makeup")!=-1){
        return Makeup
      }else{
        return SkinCheck
      }
    }
  },
  mounted(){
    console.log("hasPreviousResult: ",this.hasPreviousResult)
    if(this.$route.path.endsWith("/mykarte") || this.$route.path == "/mykarte/skincheck"){
        this.selectedGenre = "SkinCheck"
      }else if(this.$route.path.indexOf("skincare")!=-1){
        this.selectedGenre = "SkinCare"
      }else if(this.$route.path.indexOf("makeup")!=-1){
        this.selectedGenre = "Makeup"
      }
  },
  methods: {
    setSituation: function(hasPreviousResult){
      this.hasPreviousResult = hasPreviousResult
    },
    changeTab(genre){
      if(this.selectedGenre==genre) return
      this.selectedGenre = genre
      this.$router.push({path: "/mykarte/"+this.selectedGenre.toLowerCase()})
      this.$gtag.pageview({
        page_title: "Mykarte-" + this.selectedGenre,
        page_path: "/Mykarte"
      })
    }
  }
}
</script>
<style scoped>
/* メイン */
main{
  font-size: 14px;
}
h1{
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
h2{
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  padding:40px 0 28.5px 0;
  border-bottom: #777 solid 1px;
}
h3{
  font-size: 22px;
  font-weight: bold;
}
h4{
  font-size: 20px;
  font-weight: bold;
}
iframe{
  border: 0;
  width: 100%;
  height: 100%;
}
a {
  text-decoration: none;
  color: #777;
}
/* タイトル */
.mykarte_title{
  background-color: #fff;
  padding: 0 40px;
}
.mykarte_title_container{
  padding: 30px 0;
}
.mykarte_title_container p{
  padding-top: 15px;
}
.genre{
  display: inline-block;
  text-align: center;
  font-size: 16px;
  line-height: 50px;
  width: 33.3%;
  border: 1px solid #F5EAE7;
}
.genre_selected{
  background-color: #F5EAE7;
}
</style>
