<script>
import axios from "axios";
import liff from "@line/liff";

export default {
  name: "MyKarteSkinCheck",
  data() {
    return {
      latestSkinTypeId: 0,
      ROOT_SKIN_IMAGE: process.env.VUE_APP_BASE_URL,
      ROOT_IMAGE_URL: process.env.VUE_APP_IMAGE_ROOT,
      isBeforeMounted: true,
      hasPreviousResult: false
    };
  },
  created() {
    const mydata_target_url = process.env.VUE_APP_MYDATA_URL;
    const id_token = liff.getIDToken();
    axios
      .post(mydata_target_url, {
        liff_id: process.env.VUE_APP_LIFF_ID,
        skin_type_diag: true,
        skincare: { request: false },
        makeup: { request: false },
        id_token: id_token,
      })
      .then((res) => {
        if ("skin_type" in res.data) {
          if(res.data.skin_type.latest_skin_type_id==999){
            this.hasPreviousResult = false
          }else{
            this.hasPreviousResult = true
          }
          this.setMountedSituation()
          this.latestSkinTypeId = res.data.skin_type.latest_skin_type_id;
        }
        this.isBeforeMounted = false;
      })
      .catch((err) => {
        console.log("Mykarte", err.code, err.message);
        this.isBeforeMounted = false;
      });
  },
  methods: {
    setMountedSituation: function(){
      this.$emit('setSituation', this.hasPreviousResult);
    }
  }
};
</script>

<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <section
      v-if="isBeforeMounted"
      class="firstTouch"
    >
      <div class="firstTouch_container">
        <img src="@/assets/about/eye-catching-drop.png">
      </div>
    </section>
    <section
      v-else
      class="skincheck"
    >
      <div class="skincheck_container">
        <div
          v-if="latestSkinTypeId > 0 && latestSkinTypeId != 999"
          class="skincheck_img_container"
        >
          <img
            class="skincheck_img"
            :src="
              ROOT_SKIN_IMAGE +
                'flow-images/skin-check/result-images/' +
                latestSkinTypeId +
                '.png'
            "
          >
        </div>
      </div>
      <div
        v-if="latestSkinTypeId == 999"
        class="skincheck_diag_notyet"
      >
        <p>
          {{ $t('mykarteSkinCheckNoResultDesc1') }}<br>
          {{ $t('mykarteSkinCheckNoResultDesc2') }}<br>
          {{ $t('mykarteSkinCheckNoResultDesc3') }}
        </p>
        <div class="skinCheck_btn">
          <router-link to="/flow/skin-check">
            &nbsp;{{ $t('startSkinCheckButton') }}&nbsp;
          </router-link>
        </div>
      </div>
      <div class="dermatologist_inCarge_container">
        <h2>{{ $t('flowSkinCheckSupervisor') }}</h2>
        <div class="dermatologist_inCarge_inner">
          <img
            :src="ROOT_IMAGE_URL
              + '/face-icon/hamano.png'
            "
          >
          <div class="dermatologist_profile_container">
            <h3>{{ $t('hamanoName') }}</h3>
            <div class="dermatologist_profile">
              <p>{{ $t('hamanoTitle') }}</p>
              <p>{{ $t('hamanoAffiliatedEx') }}</p>
              <p>{{ $t('hamanoAffiliated6') }}</p>
            </div>
            <div class="profile_btn_container">
              <router-link
                :to="{
                  name: 'Concierges',
                  params: { incomingGenre: 'skincheck' },
                }"
                class="profile_btn"
              >
                {{ $t('conciergeSeeProfile') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="latestSkinTypeId != 999"
        class="guiding_section_inner"
      >
        <div class="guiding_section_toChatbot">
          <p>
            {{ $t('mykarteSkinCheckDesc1') }}<br>
            {{ $t('mykarteSkinCheckDesc2') }}
          </p>
          <router-link to="/flow/skin-check">
            {{ $t('skinCheckConfirmAnswer2') }}
          </router-link>
        </div>
      </div>
    </section>
  </transition>
</template>

<style scoped>
.skincheck {
  background-color: #f5eae7;
}
.skincheck_container {
}
.skincheck_img_container {
  position: relative;
}
.skincheck_img {
  width: 100%;
  object-fit: cover;
}
.skincheck_diag_notyet {
  line-height: 1.3;
  padding: 0 20px;
}
.skincheck_diag_notyet p {
  padding: 20px 0 50px;
  font-size: 18px;
  color: #777;
}
.skinCheck_btn {
  padding-bottom: 42px;
}
.skinCheck_btn a {
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  font-weight: bold;
  background-color: #fadad2;
  text-align: center;
  color: #444;
}
.dermatologist_inCarge_container {
  background-color: #f5eae7;
  margin-top: 30px;
  padding: 15px;
}
.dermatologist_inCarge_container h2 {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 20px;
}
.dermatologist_inCarge_inner {
  display: flex;
  align-items: center;
}
.dermatologist_inCarge_inner img {
  border-radius: 50%;
  width: 140px;
  border: solid 1px #ccc;
  margin: 0 10px;
}
.dermatologist_profile_container{
  margin: 0 auto;
}
.dermatologist_profile_container h3 {
  font-size: 20px;
  color: #222;
  text-align: center;
}
.dermatologist_profile {
  padding: 10px 0;
}
.dermatologist_profile p{
  font-size: 12px;
  padding-bottom: 5px;
}
.profile_btn_container {
  text-align: center;
}
.profile_btn {
  color: #777;
  background-color: #fadad2;
  padding: 11px 21px;
  font-size: 14px;
  display: inline-block;
}

.see_more {
  padding: 10px 16px;
  border: solid 1px #f29f8f;
  margin-top: 20px;
  display: inline-block;
}
.guiding_section_inner {
  padding: 100px 20px 0;
  text-align: center;
  background-color: #f5eae7;
}
a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
router-link {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
.guiding_section_toChatbot p {
  padding-bottom: 10px;
}
.guiding_section_toChatbot a {
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  color: #444;
  background-color: #fadad2;
  font-weight: bold;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.firstTouch {
  background-color: #fff;
  padding: 0 40px;
}
.firstTouch_container {
  padding: 500px 0 50px 0;
  text-align: right;
}
.firstTouch_container h1 {
  font-size: 23pxß;
  display: inline-block;
  text-align: center;
  padding-bottom: 45px;
  line-height: 1.7;
}
.firstTouch_container img {
  width: 170px;
  padding: 0 15px 50px 0;
  object-fit: cover;
}
</style>
