<script>
import liff from "@line/liff";
import axios from "axios";
import CategorySquare from "@/components/CategorySquare.vue";
import KazunosukeItemList from "@/components/KazunosukeItemList.vue";
import KazunosukeSkincareMessage from "@/views/MyKarteAreas/KazunosukeSkincareMessage.vue";
import ResultFilter from "@/components/ResultFilter.vue";

export default {
  name: "KazunosukeSkinCare",
  components: {
    CategorySquare,
    KazunosukeItemList,
    KazunosukeSkincareMessage,
    ResultFilter,
  },
  data() {
    return {
      items: [],
      result_set: {},
      hasMainConcierge: false,
      notYetConsult: false,
      // フィルター機能
      // TODO: 多言語化対応
      skincareCategories: [
        { id: 0, name: "基礎化粧品", code: "base" },
        { id: 1, name: "クレンジング", code: "cleansing" },
        { id: 2, name: "洗顔", code: "facewash" },
        { id: 3, name: "スペシャルケア", code: "special_care" },
        { id: 4, name: "blank" },
        { id: 5, name: "blank" },
      ],
      selectedCategory: "",
      // フィルター
      /* 価格 */
      minPrice: 0,
      maxPrice: 10000,
      minPriceRange: 0,
      maxPriceRange: 10000,
      filterStep: 500,
      /* 基礎化粧品。スペシャルケア */
      isSkinLotionSelected: true,
      isMilkCreamSelected: false,
      isAllInOneSelected: false,
      isCeramideSelected: false,
      isVitaminCSelected: false,
      isRetinolSelected: false,
      isNiacinamideSelected: false,
      isTranexamicAcidSelected: false,
      /* 洗顔 */
      isPoresSelected: false,
      isRoughSkinSelected: false,
      isOilySkinSelected: false,
    };
  },
  computed: {
    filteredItems() {
      if (this.items.length == 0) {
        return [];
      } else {
        return this.items.filter((item) => {
          return (
            item.official_price >= this.minPrice &&
            item.official_price <= this.maxPrice &&
            this.isMatchCategoryFilter(item, this.selectedCategory)
          )
        }).sort((a,b) => a.order - b.order);
      }
    },
    mappedCategories() {
      const length = Math.ceil(this.skincareCategories.length / 3);
      return new Array(length)
        .fill()
        .map((_, i) => this.skincareCategories.slice(i * 3, (i + 1) * 3));
    },
  },
  mounted(){
    const pagePath = this.$route.path.split("/")
    if(pagePath.length==5){
      this.selectedCategory = this.skincareCategories.find(
        (cate)  => cate.code == pagePath[4]
      ).name
      this.getLatestItems();
    }
  },
  methods: {
    getLatestItems() {
      //カテゴリーが選択状態でなければ取得しない
      if (this.selectedCategory == "") return;

      // 基本処理
      const mydata_target_url = process.env.VUE_APP_MYDATA_URL;
      const id_token = liff.getIDToken();
      axios
        .post(mydata_target_url, {
          liff_id: process.env.VUE_APP_LIFF_ID,
          skin_type_diag: false,
          skincare: {
            request: true,
            main_concierge: "かずのすけ",
            sub_category: this.selectedCategory,
          },
          makeup: { request: false },
          id_token: id_token,
        })
        .then((res) => {
          if ("skincare_items" in res.data) {
            this.items = res.data.skincare_items.Items;
            this.$gtag.event("view_item_list", {
              item_list_name: "Skincare MyKarte",
              items: this.convertItemList2GA4(this.filteredItems),
            });
            if (res.data.has_previous_result) {
              if (res.data.skincare_items.Items.length > 0) {
                this.hasMainConcierge =
                  res.data.skincare_items.Items[0].has_main_concierge;
              } else {
                this.hasMainConcierge = false;
              }
              this.notYetConsult = false;
              this.result_set = res.data.result_set;
            } else {
              this.notYetConsult = true;
            }
          }
        })
        .catch((err) => {
          this.notYetConsult = true;
          console.log("Mykarte", err.code, err.message);
        });
    },
    changeCategory(category) {
      if (this.selectedCategory == category.name) {
        this.selectedCategory = "";
        // フィルター情報の初期化
        this.refreshFilterSetting()
      } else if (this.selectedCategory == "") {
        this.selectedCategory = category.name;
        // フィルター情報の初期化
        this.refreshFilterSetting()
        this.$router.push({path: '/mykarte/skincare/kazunosuke'+"/"+category.code})
      } else {
        //新しく選択されたカテゴリーが下、現在の選択中のカテゴリーが上の場合
        //上のItemListが消失するためその分上に詰める必要がある
        const prevCategoryIndex = this.skincareCategories
          .map((x) => x.name)
          .indexOf(this.selectedCategory);
        const newCategoryIndex = this.skincareCategories.indexOf(category);
        if (
          Math.floor(prevCategoryIndex / 3) < Math.floor(newCategoryIndex / 3)
        ) {
          const messageWrapper =
            document.getElementsByClassName("messageWrapper")[0];
          let filterArea = document.getElementsByClassName("filter__wrapper filter_area")[0];
          let itemList = document.getElementsByClassName("suggested_item_list")[0];
          if(typeof itemList == "undefined") itemList = {clientHeight: 0}
          if(typeof filterArea == "undefined") filterArea = {clientHeight: 0}
          window.scrollBy(
            0,
            -(messageWrapper.clientHeight + itemList.clientHeight + filterArea.clientHeight)
          );
        }
        this.selectedCategory = category.name;
        this.$router.push({path: '/mykarte/skincare/kazunosuke'+"/"+category.code})
      }
      // フィルター情報の初期化
      this.refreshFilterSetting()
      this.getLatestItems();
    },
    isMatchCategoryFilter(item, category) {
      if (category == "基礎化粧品") {
        return this.isInSelectedCategory(item) && this.isInSelectedIngredient(item)
      } else if (category == "スペシャルケア") {
        return this.isInSelectedIngredient(item)
      } else if (category == "洗顔") {
        return this.isInSelectedFacewashCategory(item)
      } else { // クレンジングの場合
        return true
      }
    },
    // 基礎化粧品のフィルター
    isInSelectedCategory(item){
      return (this.isSkinLotionSelected && item.appliedCategory == "化粧水") || 
                (this.isMilkCreamSelected && item.appliedCategory == "乳液&クリーム") || 
                (this.isAllInOneSelected && item.appliedCategory == "オールインワン")
    },
    isInSelectedIngredient(item){
      // マッパーの準備
      const ingredientMapper = {
        "ceramide": this.isCeramideSelected,
        "vitamin": this.isVitaminCSelected,
        "retinol": this.isRetinolSelected,
        "amide": this.isNiacinamideSelected,
        "tranexamic": this.isTranexamicAcidSelected
      }
      return Object.keys(ingredientMapper)
                   .map(ing => 
                      item.features[ing] ||
                      (!item.features[ing] && !ingredientMapper[ing])
                    )
                   .every(x=>x)
    },
    // 洗顔のフィルター
    isInSelectedFacewashCategory(item){
      // マッパーの準備
      const facewashMapper = {
        "pores": this.isPoresSelected,
        "damaged": this.isRoughSkinSelected,
        "oily": this.isOilySkinSelected
      }
      return Object.keys(facewashMapper)
                   .map(feature =>
                      item.features[feature] ||
                      (!item.features[feature] && !facewashMapper[feature])
                   )
                   .every(x=>x)
    },
    refreshFilterSetting() {
      // 初期化
      this.isSkinLotionSelected = true
      this.isMilkCreamSelected = false
      this.isAllInOneSelected = false
      this.isCeramideSelected = false
      this.isVitaminCSelected = false
      this.isRetinolSelected = false
      this.isNiacinamideSelected = false
      this.isTranexamicAcidSelected = false
      this.isPoresSelected = false
      this.isRoughSkinSelected = false
      this.isOilySkinSelected = false
      if (this.selectedCategory == "スペシャルケア") {
        this.minPrice=0
        this.maxPrice=20000
        this.minPriceRange=0
        this.maxPriceRange=20000
        this.filterStep=1000
      } else {
        this.minPrice=0
        this.maxPrice=10000
        this.minPriceRange=0
        this.maxPriceRange=10000
        this.filterStep=500
      }
    },
    convertItemList2GA4(items) {
      return items.map((item, idx) => {
        return {
          item_id: item.item_id,
          item_name: item.item_name,
          item_brand: item.brand_name,
          item_category: "skincare",
          item_category2: item.category,
          index: idx,
          affiliation: "かずのすけ",
        };
      });
    },
  },
};
</script>

<template>
  <div>
    <div
      v-for="segment in mappedCategories"
      :key="segment"
      class="suggested_item"
    >
      <div class="item_categories_btn_row">
        <category-square
          v-for="(cate, index) in segment"
          :key="cate.id"
          :class="{ is_center_square: index % 3 == 1 }"
          :category="cate"
          :selected_category="selectedCategory"
          @click="changeCategory(cate)"
        />
      </div>
      <kazunosuke-skincare-message
        v-if="segment.map((x) => x.name).includes(selectedCategory)"
        :hasMainConcierge="hasMainConcierge"
        :notYetConsult="notYetConsult"
        :itemLength="items.length"
        :resultSet="result_set"
      />
      <result-filter
        v-if="
          segment.map((x) => x.name).includes(selectedCategory) &&
          !notYetConsult
        "
        v-model:minPrice="minPrice"
        v-model:maxPrice="maxPrice"
        v-model:minPriceRange="minPriceRange"
        v-model:maxPriceRange="maxPriceRange"
        v-model:filterStep="filterStep"
        v-model:isSkinLotionSelected="isSkinLotionSelected"
        v-model:isMilkCreamSelected="isMilkCreamSelected"
        v-model:isAllInOneSelected="isAllInOneSelected"
        v-model:isCeramideSelected="isCeramideSelected"
        v-model:isVitaminCSelected="isVitaminCSelected"
        v-model:isRetinolSelected="isRetinolSelected"
        v-model:isNiacinamideSelected="isNiacinamideSelected"
        v-model:isTranexamicAcidSelected="isTranexamicAcidSelected"
        v-model:isPoresSelected="isPoresSelected"
        v-model:isRoughSkinSelected="isRoughSkinSelected"
        v-model:isOilySkinSelected="isOilySkinSelected"
        :category="selectedCategory"
        class="filter_area"
      />
      <kazunosuke-item-list
        v-if="
          segment.map((x) => x.name).includes(selectedCategory) &&
          !notYetConsult
        "
        genre="skin-care"
        :category="selectedCategory"
        :items="filteredItems"
      />
    </div>
  </div>
</template>

<style scoped>
.suggested_item {
  width: 100%;
}
.item_categories_btn_row {
  display: flex;
  text-align: center;
  justify-content: space-between;
  padding-bottom: 0px;
  margin-bottom: 0px;
  width: 100%;
}
.item_categories_btn_row:last-of-type {
  justify-content: center;
}
.item_categories_btn_container {
  position: relative;
}
.item_categories_btn_inner {
  width: 100%;
}
.item_categories_btn img {
  width: 60px;
  padding: 20px 0;
}
.item_categories_btn p {
  font-size: 14px;
}
.select_condition {
  background-color: #f5eae7;
  position: relative;
}
.concierge_select_btn_container {
  width: 100%;
  padding-top: 10px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch; /* 慣性スクロール */
  -ms-overflow-style: none;
  /* Firefox 対応 */
  scrollbar-width: none;
}
.concierge_select_btn_container::-webkit-scrollbar {
  display: none;
}
.concierge_select_btn_container ul {
  max-width: 100%; /* メニューの最大幅 */
  min-width: max-content; /* メニューの最小幅 */
  margin: 0 auto;
  list-style: none;
  padding-right: 25px; /* 右側の固定分余白を空ける */
}
.concierge_select_btn {
  float: left;
  text-align: center;
  display: inline-block;
  text-decoration: none;
  padding: 7px 11px;
}
.concierge_select_btn:last-child {
  padding: 7px 0 7px 11px;
}
.concierge_select_btn_img {
  width: 65px;
  border-radius: 50%;
  border: solid 1px #ccc;
  margin-bottom: 5px;
}
.selected_concierge_icon {
  width: 75px;
  border: solid 2px #f29f8f;
}
.concierge_select_btn ul li p {
  line-height: 30px;
}
.selected_concierge_name {
  font-size: 16px;
  font-weight: bold;
}.guiding_section_inner {
  padding: 100px 40px 0;
  text-align: center;
  background-color: #f5eae7;
}
a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
router-link {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
.guiding_section_toChatbot a {
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  color: #444;
  background-color: #fadad2;
  font-weight: bold;
}
.guiding_section_toChatbot {
  padding-bottom: 50px;
}
.is_center_square {
  margin-left: 10px;
  margin-right: 10px;
}
.mappedAnswers {
  display: flex;
  flex-wrap: wrap;
}
.mappedAnswers p {
  padding-right: 10px;
  padding-bottom: 3px;
  font-size: 12px;
}
.noItemDescription {
  padding: 0 40px;
  text-align: center;
  margin: 5px 0;
}
.noItemDescription p {
  padding: 30px 0 20px;
  text-align: left;
  font-size: 14px;
  font-weight: bold;
}
.noItemDescription a {
  padding: 11px 14px;
  background-color: #fadad2;
  display: inline-block;
  color: #444;
  margin-bottom: 15px;
}
.filter_area {
  background-color: #fff;
  padding: 0 20px;
}
</style>
