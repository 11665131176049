<script>
import { computed } from "vue";
import liff from "@line/liff";
import axios from "axios";
import CategorySquare from "@/components/CategorySquare.vue";
import ItemList from "@/components/ItemList.vue";

const makeupCategories = require("@/data/makeupCategories");
const answerMapper = require("@/data/answerMapper")

export default {
  name: "Makeup",
  components: {
    CategorySquare,
    ItemList,
  },
  data() {
    return {
      ROOT_IMAGE_URL: process.env.VUE_APP_IMAGE_ROOT,
      makeupCategories,
      items: [],
      selectedCategory: "",
      isBeforeMounted: true,
      resultSet: {},
      answerMapper
    };
  },
  computed: {
    mappedCategories() {
      const length = Math.ceil(this.makeupCategories.length / 3);
      return new Array(length)
        .fill()
        .map((_, i) => this.makeupCategories.slice(i * 3, (i + 1) * 3));
    },
    filteredItems() {
      return this.items.filter(
        (item) => item.category == this.selectedCategory
      );
    },
    mappedAnswers() {
      const genre = "makeup";
      let mappedAnswers = [];
      for (let qa of Object.entries(this.resultSet)) {
        if (
          qa[0] in this.answerMapper[genre] &&
          qa[1] in this.answerMapper[genre][qa[0]]
        ) {
          if (this.answerMapper[genre][qa[0]][String(qa[1])] != "")
            mappedAnswers.push(this.answerMapper[genre][qa[0]][String(qa[1])]);
        }
      }
      return mappedAnswers;
    },
  },
  mounted() {
    this.$emit('setSituation', true);
    const mydata_target_url = process.env.VUE_APP_MYDATA_URL;
    const id_token = liff.getIDToken();
    axios
      .post(mydata_target_url, {
        liff_id: process.env.VUE_APP_LIFF_ID,
        skin_type_diag: false,
        skincare: { request: false },
        makeup: { request: true, main_concierge: "Eri" },
        id_token: id_token,
      })
      .then((res) => {
        if ("makeup_items" in res.data) {
          this.items = res.data.makeup_items;
          this.resultSet = res.data.result_set
        }
        this.isBeforeMounted = false
      })
      .catch((err) => {
        console.log("Mykarte", err.code, err.message);
        this.isBeforeMounted = false
      });
  },
  methods: {
    getMappedAnswers(){
      const genre = "makeup";
      let mappedAnswers = [];
      for (let qa of Object.entries(this.resultSet)) {
        // qa[0] => question_id, qa[1] => answer_id
        if (
          qa[0] in this.answerMapper[genre] &&
          qa[1] in this.answerMapper[genre][qa[0]]
        ) {
          if (this.answerMapper[genre][qa[0]][String(qa[1])] != "")
            mappedAnswers.push(this.answerMapper[genre][qa[0]][String(qa[1])]);
        }
      }
      return mappedAnswers;
    },
    changeCategory(category) {
      if(this.selectedCategory == category.name){
        this.selectedCategory = ""
      }else if(this.selectedCategory==""){
        this.selectedCategory = category.name
      }else{
        //新しく選択されたカテゴリーが下、現在の選択中のカテゴリーが上の場合
        //上のItemListが消失するためその分上に詰める必要がある
        const prevCategoryIndex = this.makeupCategories.map(x=>x.name).indexOf(this.selectedCategory)
        const newCategoryIndex = this.makeupCategories.indexOf(category)
        if(Math.floor(prevCategoryIndex/3)<Math.floor(newCategoryIndex/3)){
          const itemList = document.getElementsByClassName("suggested_item_list")[0]
          window.scrollBy(0, -(itemList.clientHeight))
        }
        this.selectedCategory = category.name;
        this.$gtag.event("view_item_list", {
          item_list_name: "Makeup MyKarte",
          items: this.convertItemList2GA4(this.filteredItems)
        })
      }
    },
    convertItemList2GA4(items) {
      return items.map((item, idx) => {
        return {
          item_id: item.item_id,
          item_name: item.item_name,
          item_brand: item.brand_name,
          item_category: "makeup",
          item_category2: item.category,
          index: idx,
          affiliation: "Eri"
        };
      });
    },
  },
  provide(){
    return {
      condition: computed(()=> this.getMappedAnswers())
    }
  },
};
</script>

<template>
  <transition
    name="fade"
    mode="out-in"
  >
    <section
      v-if="isBeforeMounted"
      class="firstTouch"
    >
      <div class="firstTouch_container">
        <img src="@/assets/about/eye-catching-drop.png">
      </div>
    </section>
    <section
      v-else-if="!isBeforeMounted && items.length>0"
      class="makeup_section"
    >
      <div class="concierge_info">
        <img
          :src="
            ROOT_IMAGE_URL
              + '/face-icon/'
              + 'eri'
              + '.png'
          "
        >
        <div class="concierge_profile">
          <span>{{ $t('eriTitle') }}</span>
          <h3>{{ $t('eriName') }}</h3>
          <router-link
            class="profile_btn"
            :to="{ name: 'Concierges', params: { incomingGenre: 'makeup' } }"
          >
            {{ $t('conciergeSeeProfile') }}
          </router-link>
        </div>
      </div>
      <div class="mappedAnswers">
        <p
          v-for="answer in mappedAnswers"
          :key="answer"
        >
          <!-- use v-if to show the profile by language -->
          {{ answer[$i18n.locale] }}
        </p>
      </div>

      <div class="suggested_item_container">
        <div
          v-for="segment in mappedCategories"
          :key="segment"
          class="suggested_item"
        >
          <div class="item_categories_btn_row">
            <category-square
              v-for="(cate, index) in segment"
              :key="cate.id"
              :class="{ is_center_square: index % 3 == 1 }"
              :category="cate"
              :selected_category="selectedCategory"
              @click="changeCategory(cate)"
            />
          </div>
          <div
            v-if="segment.map((x) => x.name).includes(selectedCategory)&&filteredItems.length==0"
            class="noItemCategory"
          >
            <div class="noItemDescription">
              <p>
                {{ $t('resultMakeupNoItemsDesc1') }}
              </p>
            </div>
          </div>
          <item-list
            v-if="segment.map((x) => x.name).includes(selectedCategory)"
            genre="makeup"
            :category="selectedCategory"
            :items="filteredItems"
          />
        </div>
      </div>
      <div class="guiding_section_inner">
        <div class="guiding_section_toChatbot">
          <router-link to="/flow/item-suggestions">
            {{ $t('resultSkinCareAskConciergeAgain') }}
          </router-link>
        </div>
      </div>
    </section>
    <section
      v-else
      class="notYetConsult"
    >
      <div class="notYetConsult_message_container">
        <div class="notYetConsult_message">
          <p>
            {{ $t('mykarteMakeupNoResultDesc1') }}<br>
            {{ $t('mykarteMakeupNoResultDesc2') }}
          </p>
          <router-link to="/flow/item-suggestions">
            {{ $t('guideToItemSuggestionButton') }}
          </router-link>
        </div>
      </div>
    </section>
  </transition>
</template>

<style scoped>
.makeup_section {
  background-color: #f5eae7;
}
.concierge_info {
  display: flex;
  justify-content: center;
  padding: 30px 0 15px;
}
.concierge_info img {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  border: #f29f8f solid 1px;
}
.concierge_profile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 10px;
}
.concierge_profile span {
  font-size: 12px;
  color: #777;
  padding-bottom: 2px;
}
.concierge_profile h3 {
  font-size: 22px;
  font-weight: bold;
  color: #222;
  padding-bottom: 3px;
}
.concierge_profile a {
  font-size: 14px;
  padding: 11px 14px;
  background-color: #fadad2;
  color: #777;
}
.item_categories_btn_row {
  display: flex;
  text-align: center;
  justify-content: space-between;
  padding-bottom: 0px;
  margin-bottom: 0px;
  width: 100%;
}
.item_categories_btn_container {
  position: relative;
}
.item_categories_btn_inner {
  width: 100%;
}
.item_categories_btn img {
  width: 60px;
  padding: 20px 0;
}
.item_categories_btn p {
  font-size: 14px;
}
.guiding_section_inner {
  padding: 100px 40px 0;
  text-align: center;
  background-color: #f5eae7;
}
a {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
router-link {
  background-color: transparent;
  text-decoration: none;
  color: inherit;
}
.guiding_section_toChatbot a {
  display: block;
  padding: 20px 30px;
  font-size: 18px;
  color: #444;
  background-color: #fadad2;
  font-weight: bold;
}
.guiding_section_toChatbot {
  padding-bottom: 50px;
}
.is_center_square {
  margin-left: 10px;
  margin-right: 10px;
}
.noItemCategory{
  background-color: #fff;
}
.noItemDescription{
  padding: 0 40px;
  margin: 5px 0;
}
.noItemDescription p{
  padding: 20px 0;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .3s ease;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.firstTouch{
  background-color: #fff;
  padding: 0 40px;
}
.firstTouch_container{
  padding: 500px 0 50px 0;
  text-align: right;
}
.firstTouch_container h1{
  font-size: 23pxß;
  display: inline-block;
  text-align: center;
  padding-bottom: 45px;
  line-height: 1.7;
}
.firstTouch_container img{
  width: 170px;
  padding: 0 15px 50px 0;
  object-fit: cover;
}
.notYetConsult{
  background-color: #F5EAE7;
}
.notYetConsult_message_container{
  padding: 0 40px;
  text-align: center;
}
.notYetConsult_message p{
  padding: 50px 0 30px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}
.notYetConsult_message a{
  padding: 20px 24px;
  font-size: 18px;
  background-color: #fadad2;
  display: inline-block;
  color: #222;
  margin-bottom: 20px;
  font-weight: bold;
}
.mappedAnswers {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 40px 12px;
}
.mappedAnswers p {
  padding-right: 10px;
  padding-bottom: 3px;
  font-size: 12px;
}
</style>
