<script>
import answerMapper from "@/data/answerMapper";

export default {
  name: "SkincareMessage",
  props: {
    notYetConsult: {
      type: Boolean,
    },
    hasMainConcierge: {
      type: Boolean,
    },
    resultSet: {
      type: Object,
      default: () => {},
    },
    itemLength: {
      type: Number,
    },
  },
  data() {
    return {
      otherConciergeMessage: {
        ja: "このコンシェルジュのオススメ商品がありません。他のコンシェルジュのオススメ商品をご提案いたします。",
        en: "There are no products recommended by this concierge. We will suggest a product recommended by another concierge.",
        zh: "没有这个接待员推荐的商品。我提议其他接待员推荐的商品。",
      },
      noItemMessage: {
        ja: "申し訳ございません。あなたが選択した内容でご提案できる商品はございません。",
        en: "We are sorry. We do not have any products to offer you based on your selection.",
        zh: "对不起。没有可以根据你选择的内容提出建议的商品。",
      },
      notYetConsultMessage: {
        ja: "このカテゴリーの商品はまだご相談いただいておりません。",
        en: "No products in this category have been consulted yet.",
        zh: "这个类别的商品还没有商量。",
      },
      answerMapper,
    };
  },
  computed: {
    mappedAnswers() {
      const genre = "skincare";
      let mappedAnswers = [];
      for (let qa of Object.entries(this.resultSet)) {
        // qa[0] => question_id, qa[1] => answer_id
        if (
          qa[0] in this.answerMapper[genre] &&
          qa[1] in this.answerMapper[genre][qa[0]]
        ) {
          if (this.answerMapper[genre][qa[0]][String(qa[1])] != "")
            mappedAnswers.push(this.answerMapper[genre][qa[0]][String(qa[1])]);
        }
      }
      return mappedAnswers;
    },
  },
};
</script>

<template>
  <div class="messageWrapper">
    <div
      v-if="!notYetConsult"
      class="mappedAnswers_container"
    >
      <div class="mappedAnswers">
        <p
          v-for="answer in mappedAnswers"
          :key="answer"
        >
          {{ answer[$i18n.locale] }}
        </p>
      </div>
    </div>
    <div class="noItemDescription">
      <div
        v-if="notYetConsult"
        class="notYetConsult"
      >
        <!-- use v-if to show the profile by language -->
        <p>{{ notYetConsultMessage[$i18n.locale] }}</p>
        <router-link to="/flow/item-suggestions">
          {{ $t('mykarteSkinCareToItemSuggestions') }}
        </router-link>
      </div>
      <div
        v-else-if="itemLength == 0"
        class="noItem"
      >
        <!-- use v-if to show the profile by language -->
        <p>{{ noItemMessage[$i18n.locale] }}</p>
        <router-link to="/flow/item-suggestions">
          {{ $t('mykarteSkinCareToItemSuggestions') }}
        </router-link>
      </div>
      <div v-else-if="!hasMainConcierge && itemLength > 0">
        <!-- use v-if to show the profile by language -->
        <p>{{ otherConciergeMessage[$i18n.locale] }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.messageWrapper {
  background-color: #fff;
  margin-top: 5px;
  padding-top: 10px;
}
.mappedAnswers_container{
  padding: 0 40px;
}
.mappedAnswers {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 0;
}
.mappedAnswers p {
  padding-right: 10px;
  padding-bottom: 3px;
  font-size: 12px;
}
.noItemCategory {
  background-color: #fff;
}
.noItemDescription {
  padding: 0 40px;
  text-align: center;
}
.notYetConsult p{
  padding: 30px 0 20px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}
.noItem p {
  padding: 0 0 20px;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
}
a {
  padding: 11px 14px;
  background-color: #fadad2;
  display: inline-block;
  color: #444;
  margin-bottom: 20px;
}
</style>